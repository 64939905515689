import React from "react";
import { useInView } from "react-intersection-observer";
import styles from "../styles/Projects.module.css";

const ProjectCard = ({ project }) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <a
      href={project.link}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.projectCardLink}
    >
      <div
        ref={ref}
        className={styles.projectCard}
        style={{
          opacity: inView ? 1 : 0,
          transform: inView ? "translateY(0)" : "translateY(20px)",
        }}
      >
        {project.image && (
          <img
            src={project.image}
            alt={`Image for ${project.title}`}
            className={styles.projectImage}
          />
        )}
        <h2 className={styles.projectTitle}>{project.title}</h2>
        <p className={styles.projectDescription}>{project.description}</p>
      </div>
    </a>
  );
};

const AboutProjects = () => {
  const projects = [
    {
      title: "Come at Me, Sarasota: A digital video series",
      description:
        "Bested Man vs. Food's Adam Richman as part of an innovative local news product — which we sold to Sarasota Memorial Hospital.",
      link: "https://youtu.be/re7c3LXMxys?feature=shared&t=268",
      image: "/images/sarasota.jpg", // Example image path
    },
    {
      title: "Find Facts Fast: A media literacy SMS course",
      description:
        "Led the editorial development of a text message course featuring celebrity and journalist ambassadors.",
      link: "https://www.poynter.org/mediawise/programs/find-facts-fast/",
      image: "/images/find-facts.jpg", // Example image path
    },
    {
      title: "Poynter research on AI ethics in journalism",
      description:
        "Led the development of an AI ethics summit and accompanying report outlining the principles for ethical AI product development.",
      link: "https://www.poynter.org/ethics-trust/2024/poynter-when-it-comes-to-using-ai-in-journalism-put-audience-and-ethics-first/",
      image: "/images/ai-ethics.jpg", // Example image path
    },
  ];

  return (
    <div className={styles.projectsContainer}>
      <h1 className={styles.title}>Some projects</h1>
      <p className={styles.description}>
        I've worked all over the newsroom — from covering county government to shooting videos and writing newsletters. Following my time in local news, I've developed media literacy products, co-led research and created curricula for college students and older adults.
      </p>
      <div className={styles.projectsGrid}>
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default AboutProjects;
